// npm
import React, { memo, useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Box, Container, Stack } from '@mui/material'

// constants
import { BreakpointProps } from '@constants/breakpointProps'

// components
import Lightbox from '@molecules/lightboxes/Lightbox'

interface Props extends BreakpointProps {
  image: IGatsbyImageData
  fullImageUrl: string
}

const ArtworkDetailSingleImage = ({ image, fullImageUrl }: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)

  const resetLightboxState = () => {
    setLightboxOpen(false)
    setLightboxIndex(0)
  }

  return (
    <>
      <Container maxWidth={false}>
        <Stack alignItems="center" sx={{ width: '100%' }}>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setLightboxIndex(0)
              setLightboxOpen(true)
            }}
          >
            <GatsbyImage image={image} alt="" loading="eager" />
          </Box>
        </Stack>
      </Container>
      <Lightbox
        open={lightboxOpen}
        index={lightboxIndex}
        resetParentState={resetLightboxState}
        slides={[
          {
            src: fullImageUrl,
          },
        ]}
      />
    </>
  )
}

export default memo(ArtworkDetailSingleImage)
